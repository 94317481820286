<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div
        v-if="item"
        class="mt-2 pt-75"
      >
        <b-form>
          <b-tabs pills>
            <b-tab :title="$t('administration.products.tabs.general.label')">
              <b-tabs>
                <b-tab
                  v-for="language in languages"
                  :key="language.code"
                >
                  <template #title>
                    <b-img
                      :src="require('@/assets/images/flags/' + language.code + '.svg')"
                      height="16"
                      width="16"
                      class="mr-1"
                    />
                    <span class="d-none d-sm-inline">{{ language.title }}</span>
                  </template>
                  <b-row>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.title.label')"
                        :label-for="'title.' + language.code"
                      >
                        <b-form-input
                          :id="'title.' + language.code"
                          v-model="item.title[language.code]"
                          :state="errors && errors['title.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                          {{ errors['title.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.status.label')"
                    label-for="status"
                    :state="errors && errors.status ? false : null"
                  >
                    <v-select
                      id="status"
                      v-model="item.status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="status"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.status">
                      {{ errors.status[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              :title="$t('administration.navigations.tabs.items.label')"
              @click="loadItems"
            >
              <b-card
                no-body
                class="mb-0"
              >
                <div class="m-2">
                  <b-row>
                    <b-col
                      cols="12"
                      md="12"
                    >
                      <b-input-group
                        class="mb-1"
                        :style="`justify-content:flex-end;`"
                      >
                        <b-button
                          v-if="$ability.can('store', 'navigations')"
                          variant="primary"
                          :to="{ name: 'navigation-items-store', params: { navigation_id: item.id } }"
                        >
                          <span class="text-nowrap">{{ $t('general.add') }}</span>
                        </b-button>
                      </b-input-group>

                      <v-tree
                        ref="tree"
                        :can-delete-root="true"
                        :data="items"
                        :draggable="false"
                        :expanded="true"
                        :tpl="tpl"
                        :halfcheck="false"
                        :multiple="false"
                        @del-node="destroy"
                      />
                    </b-col>
                  </b-row>
                </div>
              </b-card>
            </b-tab>
          </b-tabs>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="{ name: 'navigations-index' }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BImg,
  BTable,
  BFormInvalidFeedback,
  BOverlay, BMedia, BMediaBody, BMediaAside, BLink, BFormTextarea, BInputGroupPrepend, BInputGroup,
} from 'bootstrap-vue'
import router from '@/router'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import StatusMixin from '@/mixins/StatusMixin'
import { VTree } from 'vue-tree-halower'
import SweetAlertMixin from '@mixins/SweetAlertMixin'

export default {
  directives: {
    Ripple,
  },
  components: {
    BTab,
    BMedia,
    BMediaBody,
    BMediaAside,
    BLink,
    BFormTextarea,
    BTabs,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BImg,
    BFormInvalidFeedback,
    vSelect,
    BOverlay,
    VTree,
    BInputGroupPrepend,
    BInputGroup,
  },
  mixins: [StatusMixin, SweetAlertMixin],
  data() {
    return {
      item: null,
      languages: [],
      searchword: '',
      items: [],
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })

    await this.$http.get(`/api/administration/navigations/${router.currentRoute.params.id}`)
      .then(response => {
        const { data } = response.data

        this.item = this.transformData(data)
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.item = undefined
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          router.replace({ name: 'navigations-index' })
        }
      })
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    async loadItems() {
      if (!Object.keys(this.items).length) {
        await this.$http.get('/api/administration/navigation_items', {
          params: {
            navigation_id: this.item.id,
          },
        })
          .then(response => {
            this.items = response.data
          })
      }
    },
    search() {
      this.$refs.tree.searchNodes(this.searchword)
    },
    tpl(...args) {
      const { 0: node, 2: parent, 3: index } = args

      let titleClass = node.selected ? 'node-title node-selected' : 'node-title'

      if (node.searched) {
        titleClass += ' node-searched'
      }

      const updateRoute = { name: 'navigation-items-update', params: { id: node.id, navigation_id: this.item.id } }

      return <div style="display: flex;align-items: flex-start;justify-content: space-between;width: 100%;">
        <span class={titleClass} domPropsInnerHTML={node.title}></span>
        <div style="float:right;">
          <router-link to={updateRoute} class="btn btn-outline-secondary btn-sm">{ this.$t('general.edit') }</router-link>
          <button style="margin-left:10px;" class="btn btn-danger btn-sm" onClick={() => this.$refs.tree.delNode(node, parent, index)}>{ this.$t('general.delete') }</button>
        </div>
      </div>
    },
    destroy(node) {
      this.confirm(() => {
        this.$http.delete(`/api/administration/navigation_items/${node.delNode.id}`)
          .then(() => this.fetchData())
          .catch(error => {
            this.fetchData()
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      })
    },
    fetchData() {
      this.$http.get('/api/administration/navigation_items', {
        params: {
          navigation_id: this.item.id,
        },
      })
        .then(response => {
          this.items = response.data
        })
    },
    onSubmit() {
      const data = {
        status: this.item.status,
        title: this.item.title,
      }

      this.$http.put(`/api/administration/navigations/${this.item.id}`, data)
        .then(() => {
          router.replace({ name: 'navigations-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          this.$refs.roleForm.setErrors(error.response.data.errors)
        })
    },
    transformData(item) {
      const data = {
        id: item.id,
        status: item.status,
        title: {},
      }

      _.each(this.languages, language => {
        const translation = _.find(item.translations, { locale: language.code })

        data.title[language.code] = translation ? translation.title : null
      })

      return data
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "@core/scss/vue/libs/tree.scss";
</style>
